<template>
    <div id="user">
        <ekk-fliter 
            placeholder="用户名/手机号"
            :noExport="true"
            :noStatus="true"
            :noDate="true"
            @keyword="filters.search"
        />
        <ekk-table 
            :tableData="viewData.tableData"
            :options="viewData.options"
            :page="viewData.page"
            :feedback="viewData.feedback"
            @handle="viewData.handle"
            @handlePage="viewData.handlePage"
        />
    </div>
</template>

<script>
import util from "@/utils/util";
import ekkTable from '@/components/ekk_table.vue'
import ekkFliter from '@/components/ekk_fliter.vue'

export default {
    name:'users',
    components: { 
        ekkTable,
        ekkFliter 
    },
    data(){
        return {
            keyword:'',
            filters:{
                // 关键字筛选
                search: val => {
                    setTimeout( ()=>{
                        [this.keyword,this.viewData.page.cur] = [val, 1];
                        this.getList();
                    },300)
                },
            },
            viewData: {
                options: [
                    {prop: "avatarUrl",label: '头像',width: 60,overflow: true,src:true},
                    {prop: "nickName",label: '微信昵称',width: 100,overflow: true,},
                    {prop: "memo",label: '备注',width: 100,overflow: true,},
                    {prop: "phone",label: '手机号',width: 100,overflow: true,},
                    {prop: "gender",label: '性别',width: 80,overflow: true,},
                    // {prop: "created_at",label: '是否开通支付分',width: 100,overflow: true,},
                    {prop: "created_at",label: '注册时间',width: 100,overflow: true,},
                ],
                tableData: [],
                page: { cur: 1, size: 20, total: 0, },
                feedback: "",
                // 列表操作
                handle: (row, type) => {
                    switch (type) {
                        case "detail":
                            break;

                        default:
                            break;
                    }
                },
                // 页码切换
                handlePage: (val) => {
                    this.viewData.page.cur = val;
                    this.getList();
                },
            },
        }
    },
    created(){
        this.getList();
    },
    methods:{
        //获取列表
        getList(){
            this.viewData.feedback = 'loading';
            this.viewData.tableData = [];

            this.$http.get('users',{
                params:{
                    search:this.keyword,
                    page:this.viewData.page.cur,
                    pagesize:this.viewData.page.size,
                }
            }).then( res =>{
                let {lists,total} = res;
                let _listCopy = [];
                if(lists.length>0){
                    lists.forEach((item, index) => {
                        _listCopy.push({
                            ...item,
                            'gender':util.getGender(item.gender),
                            'created_at': util.getLocalTime({
                                data:util.getRequestTime(item.created_at) 
                            }),
                            'memo': item.memo.split(','),
                        });
                    });
                    this.viewData.feedback = '';
                }else{
                    this.viewData.feedback = 'empty';
                }
                this.viewData.tableData = _listCopy;
                this.viewData.page.total = total;
            }).catch( err =>{
                this.viewData.feedback = 'error';
            })
        },
    }
}
</script>

<style lang="scss" scoped>

</style>